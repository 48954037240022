<template>
  <div class="fragment">
<!--    <div class="section-label large"-->
<!--         v-if="warehouseStorage.name">-->
<!--      {{ warehouseStorage.name }}-->
<!--    </div>-->
    <template v-if="ran">
      <div class="row-shelf-cell"
           v-for="(row, rowIndex) in warehouseStorage['rows']"
           :key="rowIndex"
      >
        <div class="fragment"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['warehouseStorage_row'])"></div>
          <div class="row-shelf-cell__row-name site-link"
               @click="editRow(row)">
            <!--Row: A-->
            {{ $t('warehouseStorage_row.localization_value.value') }}: {{ row.name }}
          </div>
        </div>

        <div class="row-shelf-cell__section"
             v-for="(section, sectionIndex) in row.sections"
             :key="sectionIndex"
        >
          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['warehouseStorage_section'])"></div>
            <div class="row-shelf-cell__section-name site-link"
                 @click="editSection(section, row)"
            >
              {{ $t('warehouseStorage_section.localization_value.value') }}: {{ section.name }}
            </div>
          </div>
          <div class="row-shelf-cell__section-shelves-list">
            <div class="row-shelf-cell__row"
                 v-for="(shelf, shelfIndex) in section.shelves"
                 :key="shelfIndex"
            >
              <div class="fragment"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['warehouseStorage_shelf'])"></div>
                <div class="row-shelf-cell__shelf-name site-link"
                     @click="editShelf(shelf, section)"
                >
                  {{ $t('warehouseStorage_shelf.localization_value.value') }}: {{ shelf.name }}
                </div>
              </div>
              <div class="row-shelf-cell__row-list">

                <Cell
                    v-for="(cell, cellIndex) in shelf.cells"
                    :key="cellIndex"
                    :cell="cell"
                    @editCell="editCell(cell)"
                    @removeElement="removeElement(cell, attribute.cell)"
                    @printPdf="printPdf(cell.id)"
                />
                <div class="row-shelf-cell__cell row-shelf-cell__cell-add"
                     @click="addCell(shelf)"
                >
                  <div class="row-shelf-cell__cell-add-plus">
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row-shelf-cell__add-shell"
               @click="addShelf(section)"
          >
            <div class="row-shelf-cell__add-shell-i site-link site-link brown"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_addNewShelf'])"></div>
              {{ $t('warehouseStorage_addNewShelf.localization_value.value') }}
              <!--            + Add new shelf-->
            </div>
          </div>
        </div>


        <div class="row-shelf-cell__add-shell"
             @click="addSection(row)"
        >
          <div class="row-shelf-cell__add-shell-i site-link site-link brown"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['warehouseStorage_addNewSection'])"></div>
            {{ $t('warehouseStorage_addNewSection.localization_value.value') }}
<!--            + Add new section-->
          </div>
        </div>
      </div>

<!--      <div class="pt-2 pb-3" v-if="loadNewRowFlag">-->
<!--        <DotsShadowPreloader/>-->
<!--      </div>-->


      <div class="row-shelf-cell__add-row " v-if="warehouseStorage['rows']"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['warehouseStorage_addNewRow'])"></div>
        <div class="row-shelf-cell__add-row-i site-link"
             @click="addRow(warehouseStorage)"
        >
          {{ $t('warehouseStorage_addNewRow.localization_value.value') }}
<!--          + Add New Row-->
        </div>
      </div>

    </template>

    <div class="pt-2 pb-3" v-if="!warehouseStorage['rows']">
      <DotsShadowPreloader/>
    </div>
    <div v-if="length !== count" class="order-create__product-btn add-product-btn-wrap"></div>

    <ElementPopup
        v-if="isModalElementPopup"
        @close="changeElementPopup(false)"
        :element="element"
        :type="typeSelected"
        :attribute="attributeSelected"
        @reloadStorage="reloadStorage(element, attributeSelected)"
        @backCreatedItem="backCreatedItem"
    />

    <ContainerPopup
        v-if="isModalContainerPopup"
        @close="changeContainerPopup(false)"
        :element="element"
        :type="type.edit"
        :attribute="attributeSelected"
        @removeElement="removeElement(element, attributeSelected)"
        @reloadStorage="reloadStorage(element, attributeSelected)"
    />
  </div>
</template>

<script>
import ElementPopup from "../../../popups/ElementPopup/ElementPopup";
import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import Cell from "../Cell/Cell";
import {globalHelperMixin} from "../../../../../../mixins/globalMixins/globalHelperMixin";
import ContainerPopup from "../../../popups/ContainerPopup/ContainerPopup";
import {warehouseStorageMixin} from "../../../../../../mixins/warehouseStorageMixins/warehouseStorageMixin";

export default {
  name: "WarehouseStorage",
  components: {
    DotsShadowPreloader,
    ElementPopup,
    ContainerPopup,
    Cell,
  },

  mixins:[
    globalHelperMixin,
    warehouseStorageMixin
  ],

  props: {
    item: Object,
    length: Number,
    count: Number,
  },

  data() {
    return {
      warehouseStorage: {},
      element: {},
      isModalElementPopup: false,
      isModalContainerPopup: false,
      attribute: {
        row: {
          id: 1,
          name: 'row',
        },
        section: {
          id: 4,
          name: 'section',
        },
        shelf: {
          id: 2,
          name: 'shelf',
        },
        cell: {
          id: 3,
          name: 'cell',
        },
      },
      attributeSelected: {},
      type:{
        create: {
          id: 1,
          name: 'create'
        },
        edit: {
          id: 2,
          name: 'edit'
        }
      },
      typeSelected: {},
      ran: 0,
      // loadNewRowFlag: false,
      createdItem: {},
    }
  },

  mounted() {
    this.getWarehouseStorage()
    this.ran = Math.random()
  },

  methods: {
    getWarehouseStorage() {
      this.$store.dispatch('getWarehouseStorage', this.item.id).then((response) => {
        this.warehouseStorage = this.getRespData(response);
      })
    },

    /**
     * Returning the resulting element after creation with api
     **/
    backCreatedItem(item){
      this.createdItem = item
    },

    /**
     * Function to change the state of this.warehouseStorage after changing/updating/deleting items
     * @param element
     * @param attribute
     * @param deletion
     */
    reloadStorage(element, attribute, deletion = false){
      let rowId
      let sectionId
      let shelfId

      // let storageId = this.item.id
      switch (attribute.id){
        case 1: //row
          element['sections'] ? rowId = element['id'] : false

          /**
           * Deleting ROW
           */
          if(deletion){
            this._.remove(this.warehouseStorage.rows, {id: rowId})
            this.isModalContainerPopup = false
            this.ran = Math.random()
            return
          }

          /**
           * rowId ? Updating ROW : Create (push) new ROW
           */

          if(rowId) {
            this.$store.dispatch('getWarehouseStorageRow', rowId).then((response) => {
              let data = this.getRespData(response)
              let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
              this.warehouseStorage.rows[rowIndex].name = data.name
              this.ran = Math.random()
            })
          } else {
            this.$store.dispatch('getWarehouseStorage', this.warehouseStorage.id).then((response) => {
              let data = this.getRespData(response)
              let findIndex = data['rows'].findIndex(rowItem => {
                return rowItem.id === this.createdItem.id
              })
              if (this.warehouseStorage.rows.length === 0) {
                this.warehouseStorage.rows.push(this.createdItem)
                this.ran = Math.random()
              }
              else {
                this.warehouseStorage.rows.splice(findIndex, 0, this.createdItem)
                this.ran = Math.random()
              }
            })
          }
          break
        case 4: //section
          element['row_id'] ? rowId = element['row_id'] : rowId = element['id']
          element['row_id'] ? sectionId = element['id'] : false

          /**
           * Deleting SECTION
           */
          if(deletion){
            let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
            this._.remove(this.warehouseStorage.rows[rowIndex].sections, {id: sectionId})
            this.isModalContainerPopup = false
            this.ran = Math.random()
            return
          }

          /**
           * sectionId ? Updating SECTION : Create (push) new SECTION
           */
          if(sectionId){
            this.$store.dispatch('getWarehouseStorageSection', sectionId).then((response) => {
              let data = this.getRespData(response)
              rowId = data['row_id']
              let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
              let sectionIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections, ['id', sectionId])
              this.warehouseStorage.rows[rowIndex].sections[sectionIndex].name = data.name
              this.ran = Math.random()
            }).catch(error => this.createErrorLog(error))
          } else {
            this.$store.dispatch('getWarehouseStorageRow', rowId).then((response) => {
              let data = this.getRespData(response)
              let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
              let findIndex = data['sections'].findIndex(rowItem => {
                return rowItem.id === this.createdItem.id
              })
              if (this.warehouseStorage.rows[rowIndex].sections.length === 0) {
                this.warehouseStorage.rows[rowIndex].sections.push(this.createdItem)
                this.ran = Math.random()
              }
              else {
                this.warehouseStorage.rows[rowIndex].sections.splice(findIndex, 0, this.createdItem)
                this.ran = Math.random()
              }
            })
          }
          break
        case 2: //shelf
          element['section'] ? rowId = element['section']["row_id"] : rowId = element["row_id"]
          element['section'] ? sectionId = element["section"]['id'] : sectionId = element['id']
          element['section'] ? shelfId = element['id'] : false

          /**
          * Deleting SHELF
          */
          if(deletion){
            let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
            let sectionIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections, ['id', sectionId])
            this._.remove(this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves, {id: shelfId})
            this.isModalContainerPopup = false
            this.ran = Math.random()
            return
          }

          /**
           * shelfId ? Updating SHELF : Create (push) new SHELF
           */
          if(shelfId){
            this.$store.dispatch('getWarehouseStorageShelf', shelfId).then((response) => {
              let data = this.getRespData(response)
              rowId = data['section']["row_id"]
              sectionId = data['section_id']
              let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
              let sectionIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections, ['id', sectionId])
              let shelfIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves, ['id', shelfId])
              this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves[shelfIndex].name = data.name
              this.ran = Math.random()
            }).catch(error => this.createErrorLog(error))
          } else {
            this.$store.dispatch('getWarehouseStorageSection', sectionId).then((response) => {
              let data = this.getRespData(response)
              let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
              let sectionIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections, ['id', sectionId])
              let findIndex = data['shelves'].findIndex(rowItem => {
                return rowItem.id === this.createdItem.id
              })
              if (this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves.length === 0) {
                this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves.push(this.createdItem)
                this.ran = Math.random()
              }
              else {
                this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves.splice(findIndex, 0, this.createdItem)
                this.ran = Math.random()
              }
            })
          }
          break
        case 3: //cell

          /**
           * Updating and Create CELL
           * Find the desired shelf in this.warehouseStorage and overwrite all cells
           */

          element['shelf_id'] ? shelfId = element['shelf_id'] : shelfId = element['id']
          this.$store.dispatch('getWarehouseStorageShelf', shelfId).then((response) => {
            let data = this.getRespData(response)
            rowId = data["section"]['row_id']
            sectionId = data["section_id"]
            let rowIndex = this._.findKey(this.warehouseStorage.rows, ['id', rowId])
            let sectionIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections, ['id', sectionId])
            let shelfIndex = this._.findKey(this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves, ['id', shelfId])
            this.warehouseStorage.rows[rowIndex].sections[sectionIndex].shelves[shelfIndex] = data
            this.ran = Math.random()
          }).catch(error => this.createErrorLog(error))
          break
      }
    },

    changeElementPopup(val){
      this.isModalElementPopup = val
    },

    changeContainerPopup(val){
      this.isModalContainerPopup = val
    },

    getAttrForPopup(attr, type){
      this.attributeSelected = attr
      this.typeSelected = type
      this.isModalElementPopup = true
    },

    addRow(storage){
      this.element = storage
      this.getAttrForPopup(this.attribute.row, this.type.create)
    },

    editRow(val){
      this.element = val
      this.attributeSelected = this.attribute.row
      this.changeContainerPopup(true)
    },

    addSection(val){
      this.element = val
      this.getAttrForPopup(this.attribute.section, this.type.create)
    },

    editSection(val, row){
      this.element = val
      this.element.row = row
      this.attributeSelected = this.attribute.section
      this.changeContainerPopup(true)
    },

    addShelf(val){
      this.element = val
      this.getAttrForPopup(this.attribute.shelf, this.type.create)
    },

    editShelf(val, section){
      this.element = val
      this.element.section = section
      this.attributeSelected = this.attribute.shelf
      this.changeContainerPopup(true)
    },

    addCell(val){
      this.element = val
      this.getAttrForPopup(this.attribute.cell, this.type.create)
    },

    editCell(val){
      this.element = val
      this.getAttrForPopup(this.attribute.cell, this.type.edit)
    },
  },
}
</script>

<style lang="scss">

</style>