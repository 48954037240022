<template>
  <CellsContentAdmin
      v-if="loadedWarehouses"
      :warehouseStorages="warehouseStorages"
      @changeTab="changeTab"
  />
</template>

<script>
import CellsContentAdmin from "./CellsContentAdmin/CellsContentAdmin";

export default {
  name: "CellsContent",
  components: {
    CellsContentAdmin,
  },

  data() {
    return {
      warehouseStorages: {},

      warehouseItem: {
        id: '',
        name: '',
        warehouse_id: '',
        active: '',
      },

      loadedWarehouses: false,
    }
  },

  mounted() {
    this.getWarehouseStorages()
  },

  methods: {
    getWarehouseStorages() {
      this.$store.dispatch('fetchWarehouseStorage').then((response) => {
        let warehouses = this.getRespPaginateData(response)
        let warehousesLocal = []

        warehouses.map((item, index) => {
          this.warehouseItem['id'] = item.id
          this.warehouseItem['name'] = item.name
          this.warehouseItem['warehouse_id'] = item.warehouse_id
          this.warehouseItem['active'] = index === 0
          warehousesLocal.push(this._.clone(this.warehouseItem))
        })

        this.warehouseStorages = warehousesLocal
        this.loadedWarehouses = true
      })
    },

    changeTab(id) {
      this.loadedWarehouses = false
      this._.find(this.warehouseStorages, {active: true}).active = false
      this._.find(this.warehouseStorages, {id: id}).active = true
      setTimeout(() => {
        this.loadedWarehouses = true
      }, 0)
    },

  },
}
</script>

<style lang="scss">


@import "../../../../../scss/colors";
@import "../../../../../scss/mixins/mixins";

.row-shelf-cell {
  display: flex;
  flex-direction: column;

  &__section {
    padding-top: 15px;
  }

  &__row-name, &__section-name {
    padding-left: 60px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $borderBrown;
    margin-bottom: 12px;
  }

  &__row {
    display: flex;
  }

  &__shelf-name {
    max-width: 60px;
    min-width: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $borderBrown;
    height: 55px;
    word-break: break-all;
  }

  &__row-list {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
  }

  &__cell {
    width: 58px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $mainBg;
    border: 1px solid $brown;
    border-top: 0;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: -1px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      width: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background: $brown;
    }

    &:hover {
      background: $accent;

      & > div {
        color: white;
      }
    }
  }

  &__cell-btn {
    position: absolute;
    top: 100%;
    left: 20%;
    transform: translateX(-30%);
    z-index: 1;
    background: white;
    padding: 10px 15px 0;
    display: none;
    box-shadow: 2px 2px 10px -6px rgba(0, 0, 0, 65%);
    @include for-1200 {
      right: 0;
      left: auto;
      transform: translateX(0);
      flex-wrap: wrap;
      max-width: 280px;
    }
  }

  &__cell:hover &__cell-btn,
  .hover &__cell-btn {
    display: flex;
  }

  &__cell-btn-i {
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 2px;
      width: 1px;
      height: 12px;
      background: $accent;
      opacity: .2;
    }
  }

  &__cell-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    word-break: break-all;
  }

  &__cell-add {
  }

  &__cell-add-plus {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $brown;
  }

  &__add-shell {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  &__add-shell-i {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }

  &__add-row {
    padding-left: 60px;
    margin-top: 9px;
    padding-bottom: 50px;
  }

  &__add-row-i {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $brown;
    width: 100%;
    height: 56px;
    border: 1px solid #8F7A61;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>