<template>
  <div class="row-shelf-cell__cell"
     @mouseover="showBtn = true"
  >
    <div class="row-shelf-cell__cell-name">
      {{ cell.name }}
    </div>
    <template v-if="showBtn">
      <div class="row-shelf-cell__cell-btn">
        <div class="row-shelf-cell__cell-btn-i"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_print'])"></div>
          <LinkButton
              :value="$t('warehouseStorage_print.localization_value.value')"
              :type="'print'"
              @click.native="$emit('printPdf')"
          />
        </div>
        <div class="row-shelf-cell__cell-btn-i"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
          <LinkButton
              :value="$t('common_edit.localization_value.value')"
              :type="'edit'"
              @click.native="$emit('editCell')"
          />
        </div>
        <div class="row-shelf-cell__cell-btn-i"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
          <LinkButton
              :value="$t('common_delete.localization_value.value')"
              :type="'delete'"
              @click.native="$emit('removeElement')"
          />
        </div>
        <div class="row-shelf-cell__cell-btn-i"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_products'])"></div>
          <LinkButton
              :value="$t('warehouseStorage_products.localization_value.value')"
              :type="'product'"
              :link="$store.getters.GET_PATHS.financeReportCells + '?id=' + cell.id"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LinkButton from "../../../../../UI/buttons/LinkButton/LinkButton";

export default {
  name: "Cell",
  components: {
    LinkButton,
  },

  props: {
    cell: Object
  },

  data(){
    return{
      showBtn: false,
    }
  },

  methods: {

  }
}
</script>

<style scoped>

</style>