<template>
  <div class="fragment">
    <modal
        @close="$emit('close')"
        class="custom-popup-modal custom-popup medium-size"
    >
      <template slot="header">
        <div v-if="attribute.id === 1"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([`warehouseStorage_${attribute.name}`])"></div>
          {{ $t(`warehouseStorage_${attribute.name}.localization_value.value`) }}: {{row.name}}
        </div>
        <div v-if="attribute.id === 4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([`warehouseStorage_${attribute.name}`])"></div>
          {{ $t(`warehouseStorage_${attribute.name}.localization_value.value`) }}: {{section.name}}
        </div>
        <div v-if="attribute.id === 2"
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([`warehouseStorage_${attribute.name}`])"></div>
          {{ $t(`warehouseStorage_${attribute.name}.localization_value.value`) }}: {{shelf.name}}
        </div>
      </template>
      <template slot="body">
        <div class="custom-popup__content">
          <div class="custom-row mb-3">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_name'])"></div>
              <DefaultInput
                  :label="$t('warehouseStorage_name.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.name}"
                  :error="validation.name"
                  :errorTxt="$t(`${validationTranslate.name}.localization_value.value`)"
                  v-model="name"
              />
            </div>
            <div class="custom-col custom-col--50 custom-col--sm-100 mb-0"></div>
            <div class="custom-col">
              <div class="fragment" v-if="attribute.id === 1"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['warehouseStorage_numberOfSections'])"></div>
                {{ $t('warehouseStorage_numberOfSections.localization_value.value') }}: {{row.sections ? row.sections.length : 0}}
              </div>
              <div class="fragment" v-if="attribute.id === 4"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['warehouseStorage_numberOfShelves'])"></div>
                {{ $t('warehouseStorage_numberOfShelves.localization_value.value') }}: {{section.shelves ? section.shelves.length : 0}}
              </div>
              <div class="fragment" v-if="attribute.id === 2"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['warehouseStorage_numberOfShelves'])"></div>
                {{ $t('warehouseStorage_numberOfCells.localization_value.value') }}: {{shelf.cells ? shelf.cells.length : 0}}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="custom-popup__btn align-items-center d-flex flex-wrap"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
              'common_cancel',
              'common_delete',
              'common_save'
          ])"></div>
          <span
              class="site-link site-link--alt  mr-4"
              @click="$emit('close')"
          >
             {{ $t('common_cancel.localization_value.value') }}
          </span>
          <MainButton
              class="custom-popup__btn-i"
              :value="$t('common_delete.localization_value.value')"
              @click.native="$emit('removeElement')"
              v-bind:class="{'disabled-btn' : $store.getters.getWarehouseStorageBtn}"
          />
          <MainButton
              class="custom-popup__btn-i ml-auto"
              :value="$t('common_save.localization_value.value')"
              @click.native="save(getArgData())"
              v-bind:class="{'disabled-btn' : $store.getters.getWarehouseStorageBtn}"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {globalHelperMixin} from "../../../../../mixins/globalMixins/globalHelperMixin";
import {warehouseStorageMixin} from "../../../../../mixins/warehouseStorageMixins/warehouseStorageMixin";


export default {
  name: "ContainerPopup",
  components: {
    Modal,
    MainButton,
    DefaultInput,
  },

  mixins: [
    globalHelperMixin,
    warehouseStorageMixin
  ],

  props: {
    element: Object,
    attribute: Object,
    type: Object,
  },

  data() {
    return {
      data: {},
      storage: {},
      row: {},
      section: {},
      shelf: {},

      name: '',
      mixed: false,

      validation: {
        name: false,
      },
      validationTranslate: {
        name: "",
      },
      validationTxt: {
        name: false,
      },
    }
  },

  mounted() {
    switch (this.attribute.id) {
      case 1: // row
        /**
         * this.element = row
         * */
        this.row = this.element
        this.storage['id'] = this.element['storage_id']
        this.generateFields(this.element)
        break
      case 4: // section
        /**
         * this.element = section
         * */
        this.row = this.element.row
        this.section = this.element
        this.generateFields(this.element)
        break
      case 2: // shelf
        /**
         * this.element = shelf
         * */
        this.section = this.element.section
        this.shelf = this.element
        this.generateFields(this.element)
        break
    }
  },

  methods: {
    generateFields(element){
      this.name = element.name
    },

    getArgData(){
      return {
        name: this.name,
        attribute: this.attribute,
        type: this.type,
        storage: this.storage,
        row: this.row,
        section: this.section,
        shelf: this.shelf,
        element: this.element,
      }
    },

    reloadStorage(){
      this.$emit('reloadStorage')
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../../../../scss/colors";
@import "../../../../../scss/mixins/mixins";

</style>
