<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo
              :warehouseStorage="_.find(warehouseStorages, {active: true})"
          />
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head mb-3">
            <div class="content-tabs content-tabs--separator mb-4">
              <!--                 v-bind:class="{'loading-tabs': $store.getters.getOrdersFBMLoading === true,}"-->
              <template v-for="(item, index) in warehouseStorages">
                <div class="content-tabs__item"
                     :key="index"
                     v-bind:class="{active: item.active}"
                     @click="$emit('changeTab', item.id)"
                >
                  <div class="content-tabs__ico"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <!--                    <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
                    <template>
                      {{item.name}}
                    </template>
                  </div>
                </div>
              </template>
            </div>

<!--            <div class="order-create__head-line">-->
<!--              <div class="order-create__header-col">-->
<!--                <router-link :to="$store.getters.GET_PATHS.settings" class="detail-page__head-back">-->
<!--                  <LinkBack-->
<!--                      :value="'Back to Orders' "-->
<!--                  />-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->

            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_rowShelfCell'])"></div>
              {{ $t('warehouseStorage_rowShelfCell.localization_value.value') }}
<!--              Row-Shelf-Cell-->
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_pleaseFillOut'])"></div>
              {{ $t('warehouseStorage_pleaseFillOut.localization_value.value') }}
<!--              Please, fill out all the information below to complete the structure-->
            </div>
          </div>
        </template>

        <template slot="body">
          <form class="order-create__section">
<!--            {{warehouseStorages}}-->



            <div class="order-create__row custom-row">
              <div class="custom-col">
<!--                  v-for="(item, index) in warehouseStorages"-->
<!--                  :key="index"-->
                <WarehouseStorage
                  :item="_.find(warehouseStorages, {active: true})"
                />
<!--                  :length="warehouseStorages.length"-->
<!--                  :count="parseInt(index)"-->
              </div>
            </div>
          </form>
        </template>
      </CardRightBlock>
    </div>


  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  // import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import WarehouseStorage from "../../components/WarehouseStorage/WarehouseStorage";

  export default {
    name: "CellsContentAdmin",
    components: {
      // MainButton,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      // LinkBack,
      WarehouseStorage,
    },

    props: {
      warehouseStorages: Array
    },

    data(){
      return{
      }
    },

    methods: {

    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/colors";
  @import "../../../../../../scss/mixins/mixins";


  .row-shelf-cell{
    display: flex;
    flex-direction: column;

    &__row-name{
      padding-left: 60px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $borderBrown;
      margin-bottom: 12px;
    }

    &__row{
      display: flex;
    }

    &__shelf-name{
      max-width: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $borderBrown;
      height: 55px;
    }

    &__row-list{
      display: flex;
      flex-wrap: wrap;
      border-radius: 2px;
    }

    &__cell{
      width: 58px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $mainBg;
      border: 1px solid $brown;
      border-top: 0;
      box-sizing: border-box;
      cursor: pointer;
      margin-left: -1px;
      position: relative;

      &:before{
        content: '';
        position: absolute;
        top: -1px;
        width: calc(100% + 2px);
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background: $brown;
      }

      &:hover{
        background: $accent;

        & > div {
          color: white;
        }
      }
    }

    &__cell-btn{
      position: absolute;
      top: 100%;
      left: 20%;
      transform: translateX(-30%);
      z-index: 1;
      background: white;
      padding: 10px 15px 0;
      display: none;
      box-shadow: 2px 2px 10px -6px rgba(0, 0, 0, 65%);

      @include for-1200{
        right: 0;
        left: auto;
        transform: translateX(0);
        flex-wrap: wrap;
        max-width: 280px;
      }
    }

    &__cell:hover &__cell-btn,
    .hover &__cell-btn{
      display: flex;
    }

    &__cell-btn-i{
      padding-bottom: 10px;
      padding-left: 12px;
      padding-right: 12px;
      position: relative;

      &:after{
        content: '';
        position: absolute;
        right: 0;
        top: 2px;
        width: 1px;
        height: 12px;
        background: $accent;
        opacity: .2;

      }
    }
    
    &__cell-name{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &__cell-add{

    }

    &__cell-add-plus{
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: $brown;
    }

    &__add-shell{
      margin-bottom: 15px;
    }

    &__add-shell-i{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }

    &__add-row{
      padding-left: 60px;
      margin-top: 9px;
      padding-bottom: 50px;
    }

    &__add-row-i{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $brown;
      width: 100%;
      height: 56px;
      border: 1px solid #8F7A61;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }

</style>
