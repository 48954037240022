import {validation} from "../../services/validation";

export const warehouseStorageMixin = {

  methods: {

    /**
     * Validation field Name in all popups
     * @returns {boolean}
     */

    fieldValidation(name) {
      let validationItems = {
        name: name
      }
      let validationOptions = {
        name: {type: ['empty']},
      }
      let validate = validation(validationItems, validationOptions)

      Object.keys(validationItems).map(item => {
        this.validation[item] = validate.validation[item] ? validate.validation[item] : false
        this.validationTranslate[item] = validate.validationError[item] ? validate.validationError[item] : ''
      })

      return validate.isValidate
    },


    save(argData) {
      if (!this.fieldValidation(argData.name)) return

      let data = {
        name: argData.name
      }

      switch (argData.attribute.id) {
        case 1: // row
          data['storage_id'] = argData.storage.id
          break
        case 4: // section
          data['row_id'] = argData.row.id
          break
        case 2: // shelf
          data['section_id'] = argData.section.id
          break
        case 3: // cell
          data['shelf_id'] = argData.shelf.id
          data['mixed'] = argData.mixed
          break
      }

      let saveType = `createWarehouseStorage${this.capitalize(argData.attribute.name)}`

      if (argData.type.id === 2) { //edit = update
        saveType = `updateWarehouseStorage${this.capitalize(argData.attribute.name)}`
        data = {
          data: data,
          id: argData.element.id
        }

        switch (argData.attribute.id) {
          case 1: // row
            data['storage_id'] = argData.storage.id
            break
          case 4: // section
            data['row_id'] = this.row.id
            break
          case 2: // shelf
            data['section_id'] = this.section.id
            break
        }
      }

      this.$store.dispatch(saveType, data).then(response => {
        response.status = response?.data?.status || response?.response?.status
        switch (response.status) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (this.type.id === 2) { //edit
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            let createdItem =  this.getRespData(response)
            this.$emit('backCreatedItem', createdItem)
            this.$emit('reloadStorage')
            this.$emit('close')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },



    removeElement(element, attribute){
      let id = element.id

      let text = {
        title: `warehouseStorage_delete${this.capitalize(attribute.name)}`,
        txt: `warehouseStorage_delete${this.capitalize(attribute.name)}Description`,
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let deleteType = `deleteWarehouseStorage${this.capitalize(attribute.name)}`

      let confirm = () => {

        this.$store.dispatch(deleteType, id).then((response) => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.reloadStorage(element, attribute, true)
              this.openNotify('success', 'common_notificationRecordDeleted')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },


    printPdf(id){
      this.$store.dispatch('getWarehouseStoragePrint', id).then((response) => {

        response.status = response?.data?.status || response?.response?.status
        switch (response.status) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            let base64 = this.getRespData(response)['fileContentBase64']
            this.globalOpenPdfBase64(base64)
            break
          }

            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })
    },

  }
}
