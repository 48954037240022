<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner">
      <template v-if="warehouseStorage">
        <div class="user-info-admin__caption"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['warehouseStorage_storage'])"></div>
          {{ $t('warehouseStorage_storage.localization_value.value') }}:
        </div>
        <div class="user-info-admin__large">
          <!--        {{ new Date() | moment("DD MMM, YYYY") }}-->
          {{warehouseStorage.name}}
        </div>
<!--        <div class="user-info-admin__history">-->
<!--          <div class="site-link text-decoration-underline btn-style brown"-->
<!--               @click="changeHistoryOfChanges(true)"-->

<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>-->
<!--            {{ $t('common_CheckHistory.localization_value.value') }}-->
<!--            &lt;!&ndash;          Check History of Changes&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
      </template>
    </div>



  </div>
</template>

<script>


  export default {
    name: "UserInfo",
    components: {
    },

    props: {
      warehouseStorage: Object
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },
    }
  }
</script>

<style scoped>

  @media(min-width: 680px){
    .user-info-admin__large{
      font-weight: bold;
      font-size: 40px;
      line-height: normal;
    }
  }
</style>