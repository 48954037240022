<template>
  <div class="fragment">
    <modal
        @close="$emit('close')"
        class="custom-popup-modal custom-popup medium-size"
    >
      <template slot="header">
        <div v-if="type.id === 1"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([`warehouseStorage_${attribute.name}AddNew`])"></div>
          {{$t(`warehouseStorage_${attribute.name}AddNew.localization_value.value`)}}
<!--          Add New {{ attribute.name }}-->
        </div>
        <div v-if="type.id === 2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([`warehouseStorage_${attribute.name}Edit`])"></div>
          {{$t(`warehouseStorage_${attribute.name}Edit.localization_value.value`)}} {{data.name}}
<!--          Edit {{ attribute.name }}-->
        </div>
      </template>
      <template slot="body">
        <div class="custom-popup__content">
          <div class="custom-row mb-3">
            <!--            <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100">-->
            <!--              <DefaultInput-->
            <!--                  :label="'Code'"-->
            <!--                  v-model="input01"-->
            <!--              />-->
            <!--            </div>-->
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_name'])"></div>
              <DefaultInput
                  :label="$t('warehouseStorage_name.localization_value.value')"
                  v-bind:class="{'ui-no-valid': validation.name}"
                  :error="validation.name"
                  :errorTxt="$t(`${validationTranslate.name}.localization_value.value`)"
                  v-model="name"
              />
            </div>
            <div class="custom-col custom-col--50 custom-col--sm-100 mb-0"></div>


<!--                  v-if="_.has(row, 'name')"-->
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-if="attribute.id === 2 || attribute.id === 3 || attribute.id === 4"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_row'])"></div>
              <DefaultInput
                  :label="$t('warehouseStorage_row.localization_value.value')"
                  v-model="row['name']"
                  :disabled="true"
              />
            </div>

            <!--                  v-if="_.has(section, 'name')"-->
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-if="attribute.id === 2 || attribute.id === 3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_section'])"></div>
              <DefaultInput
                  :label="$t('warehouseStorage_section.localization_value.value')"
                  v-model="section['name']"
                  :disabled="true"
              />
            </div>

<!--                 v-if="_.has(shelf, 'name')"-->
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-if="attribute.id === 3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_shelf'])"></div>
              <DefaultInput
                  :label="$t('warehouseStorage_shelf.localization_value.value')"
                  v-model="shelf['name']"
                  :disabled="true"
              />
            </div>

            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-if="attribute.id === 3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['warehouseStorage_mixed'])"></div>
              <DefaultCheckbox
                  :label="$t('warehouseStorage_mixed.localization_value.value')"
                  :value="mixed"
                  @change.native="mixed = !mixed"
              />
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="custom-popup__btn align-items-center d-flex flex-wrap justify-content-end"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
              'common_cancel',
              'common_create',
              'common_save'
          ])"></div>
          <span
              class="site-link site-link--alt  mr-4"
              @click="$emit('close')"
          >
            {{ $t('common_cancel.localization_value.value') }}
          </span>
          <MainButton
              class="custom-popup__btn-i"
              :value="$t('common_create.localization_value.value')"
              v-if="type.id === 1"
              @click.native="save(getArgData())"
              v-bind:class="{'disabled-btn' : $store.getters.getWarehouseStorageBtn}"
          />
          <MainButton
              v-if="type.id === 2"
              class="custom-popup__btn-i"
              :value="$t('common_save.localization_value.value')"
              @click.native="save(getArgData())"
              v-bind:class="{'disabled-btn' : $store.getters.getWarehouseStorageBtn}"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {globalHelperMixin} from "../../../../../mixins/globalMixins/globalHelperMixin";
import {warehouseStorageMixin} from "../../../../../mixins/warehouseStorageMixins/warehouseStorageMixin";


export default {
  name: "ElementPopup",
  components: {
    Modal,
    MainButton,
    DefaultInput,
    DefaultCheckbox,
  },

  mixins: [
    globalHelperMixin,
    warehouseStorageMixin
  ],

  props: {
    element: Object,
    type: Object,
    attribute: Object,
  },

  data() {
    return {
      data: {},
      storage: {},
      row: {},
      section: {},
      shelf: {},
      cell: {},

      name: '',
      mixed: false,

      validation: {
        name: false,
      },
      validationTranslate: {
        name: "",
      },
      validationTxt: {
        name: false,
      },
    }
  },

  mounted() {
    switch (this.attribute.id) {
      case 1: // row
        /**
         * this.element = storage
         * */
        this.storage = this.element
        break
      case 4: // section
        /**
         * this.element = row
         * */
        this.generateRowFields(this.element.id)
        break
      case 2: // shelf
        /**
         * this.element = section
         * */
        this.generateSectionFields(this.element.id)
        break
      case 3: // cell
        /**
         * if Create cell => this.element = shelf
         * If Edit cell => this.element = cell
         * */
        this.generateCellFields(this.element)
        break
    }
  },

  methods: {
    generateCellFields(element) {
      if (this.type.id === 1) { //create
        this.generateShelfFields(element.id)
        this.generateSectionFields(element.section_id)
      }
      if (this.type.id === 2) { //edit
        this.$store.dispatch('getWarehouseStorageCell', element.id).then((response) => {
          this.data = this.getRespData(response)
          this.generateShelfFields(this.data['shelf']['id'])
          this.generateSectionFields(this.data['shelf']['section_id'])
        }).then(() => {
          this.name = this.data.name ? this.data.name : ''
          this.mixed = this.data.mixed
        }).catch(error => this.createErrorLog(error))
      }
    },

    generateSectionFields(id) {
      this.$store.dispatch('getWarehouseStorageSection', id).then((response) => {
        this.section = this.getRespData(response)
        this.row = this.getRespData(response)?.row
      })
    },

    generateShelfFields(id) {
      this.$store.dispatch('getWarehouseStorageShelf', id).then((response) => {
        this.shelf = this.getRespData(response)
      })
    },

    generateRowFields(id) {
      this.$store.dispatch('getWarehouseStorageRow', id).then((response) => {
        this.row = this.getRespData(response)
      })
    },

    getArgData(){
      return {
        name: this.name,
        attribute: this.attribute,
        type: this.type,
        storage: this.storage,
        row: this.row,
        section: this.section,
        shelf: this.shelf,
        cell: this.cell,
        mixed: this.mixed,
        element: this.element,
      }
    },

    // reloadStorage(createdItem = false){
    //   this.$emit('reloadStorage')
    // },
  }
}
</script>

<style scoped lang="scss">

@import "../../../../../scss/colors";
@import "../../../../../scss/mixins/mixins";

</style>
